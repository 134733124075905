import {
  Avatar,
  Backdrop,
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CircularProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import {
  ArrowForwardIosRounded,
  HelpOutline,
  LogoutTwoTone,
  PersonOutlineRounded,
  SchoolRounded,
  StorefrontRounded,
  SyncLockRounded,
} from "@mui/icons-material";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";

import theme from "util/theme";

import { connect, useDispatch, useSelector } from "react-redux";

// import { logoutUser, updateUser } from "../../redux/actions/userActions";
import { logoutUser } from "../../redux/actions/userActions";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { useState } from "react";
import axiosInstance from "util/axiosInstance";
import SelectProfileDrawer from "screens/Login/SelectProfileDrawer";
import { AlertNotificationContext } from "context-api/alert-context/alert-state";
import axios from "axios";
import { API_FINANCIAL, isB2b, platform } from "apiConfig";
import { LoadingButton } from "@mui/lab";

function ProfileDrawer({
  showProfileDrawer,
  setShowProfileDrawer,
  setLoading,
  loading,
  userData,
  setUserData,
  ...props
}) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { setAlert } = useContext(AlertNotificationContext);

  const [showProfilesDialog, setShowProfilesDialog] = useState(false);

  const studentReferral = useSelector(
    (state) => state?.user?.systemConfig?.["is_referral"]
  );

  const erpId = useSelector((store) => store?.user?.userData?.erp);

  useEffect(() => {
    const source = axios.CancelToken.source();
    fetchUserDetails(source);

    return () => {
      source.cancel("Pervious request canceled");
    };
  }, []);

  const fetchUserDetails = (source) => {
    setLoading(true);
    axiosInstance
      .get(`erp_user/user-data/?erp_user_id=${props?.erpUserId}`, {
        cancelToken: source.token,
      })
      .then((res) => {
        if (res.data.status_code === 200) {
          setUserData(res?.data?.result);
          const newUserData = {
            ...props.user.userData,
            role_details: {
              ...props.user?.userData?.role_details,
              name: res?.data?.result?.name,
              user_profile: res?.data?.result?.profile,
            },
          };

          dispatch({
            type: "SET_USER",
            payload: newUserData,
          });

          localStorage.setItem("user_details", JSON.stringify(newUserData));
        } else {
          setAlert("error", res?.data?.message);
        }
      })
      .catch((err) => {
        if (err?.code != "ERR_CANCELED") {
          setAlert("error", err?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const fcmLogout = () => {
  //   setFcmLogoutRunning(true);
  //   axiosInstance
  //     .get("/erp_user/fcm-logout/")
  //     .then((res) => {
  //       if (res?.data?.status_code == 200) {
  //         props.logoutUser(navigate);
  //       } else {
  //         setAlert("error", "fcm logout failed");
  //         console.log("fcm logout error", res);
  //       }
  //     })
  //     .catch((err) => {
  //       setAlert("error", "fcm logout failed");
  //       console.log("fcm logout error", err);
  //     })
  //     .finally(() => {
  //       setFcmLogoutRunning(false);
  //     });
  // };

  const handleLogout = () => {
    // if (platform == "mb_droid" || platform == "mb_ios") {
    //   fcmLogout();
    // } else {
    props.logoutUser(navigate);
    // }
  };

  const [fcmLogoutRunning, setFcmLogoutRunning] = useState(false);

  // Wallet
  const [storeWalletData, setStoreWalletData] = useState(null);
  const [isStoreWalletLoading, setIsStoreWalletLoading] = useState(false);
  const [acadWalletData, setAcadWalletData] = useState(null);
  const [isAcadWalletLoading, setIsAcadWalletLoading] = useState(false);

  const fetchAcadWallet = () => {
    setIsAcadWalletLoading(true);
    const params = {
      student_id: erpId,
    };
    axiosInstance
      .get(API_FINANCIAL + "/apiV1/wallet-list/", { params })
      .then((res) => {
        if (res?.status == 200) {
          setAcadWalletData(res?.data);
        } else {
          setAlert("error", "Something went wrong!");
        }
      })
      .catch((err) => {
        setAlert("error", "Something went wrong!");
      })
      .finally(() => {
        setIsAcadWalletLoading(false);
      });
  };

  const fetchStoreWallet = () => {
    setIsStoreWalletLoading(true);
    const params = {
      student_id: erpId,
    };
    axiosInstance
      .get(API_FINANCIAL + "/apiV1/store-wallet-list/", { params })
      .then((res) => {
        if (res?.status == 200) {
          setStoreWalletData(res?.data);
        } else {
          setAlert("error", "Something went wrong!");
        }
      })
      .catch((err) => {
        setAlert("error", "Something went wrong!");
      })
      .finally(() => {
        setIsStoreWalletLoading(false);
      });
  };

  return (
    <Box sx={{ borderRadius: "24px 24px 0px 0px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={fcmLogoutRunning}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Card
        sx={{
          overflow: "auto",
          borderRadius: 0,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          boxShadow: "none",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2.5,
            }}
          >
            {!isB2b && props?.user?.userData?.user_level === 13 && (
              <Box
                sx={{
                  p: 1.5,
                  border: theme.border.main,
                  borderRadius: 4,
                }}
                boxShadow={theme.shadows[1]}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <SchoolRounded color="action" sx={{ pr: 1 }} />
                    <Typography variant="md" type="medium">
                      Academic Wallet
                    </Typography>
                  </Box>
                  {acadWalletData ? (
                    <Typography sx={{ ml: 2 }} variant="md" type="bold">
                      ₹{" "}
                      {acadWalletData?.length > 0
                        ? new Intl.NumberFormat("en-IN").format(
                            acadWalletData?.[0]?.amount
                          )
                        : 0}
                    </Typography>
                  ) : (
                    <>
                      {isAcadWalletLoading ? (
                        <CircularProgress color="primary" size={18} />
                      ) : (
                        <Button
                          sx={{ ml: 2, py: 0 }}
                          variant="text"
                          onClick={fetchAcadWallet}
                        >
                          Check Balance
                        </Button>
                      )}
                    </>
                  )}
                </Box>
                <Box sx={{ pt: 1 }} />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <StorefrontRounded color="action" sx={{ pr: 1 }} />
                    <Typography variant="md" type="medium">
                      Store Wallet
                    </Typography>
                  </Box>
                  {storeWalletData ? (
                    <Typography sx={{ ml: 2 }} variant="md" type="bold">
                      ₹{" "}
                      {storeWalletData?.length > 0
                        ? new Intl.NumberFormat("en-IN").format(
                            storeWalletData?.[0]?.store_amount
                          )
                        : 0}
                    </Typography>
                  ) : (
                    <>
                      {isStoreWalletLoading ? (
                        <CircularProgress color="primary" size={18} />
                      ) : (
                        <Button
                          sx={{ ml: 2, py: 0 }}
                          variant="text"
                          onClick={fetchStoreWallet}
                        >
                          Check Balance
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1.5,
                border: theme.border.main,
                borderRadius: 4,
              }}
              onClick={() => {
                navigate("/profile/edit", {
                  state: userData,
                });
              }}
              boxShadow={theme.shadows[1]}
            >
              <Avatar
                sx={{
                  color: theme.palette.blueDark.dark2,
                  backgroundColor: theme.palette.gray.light3,
                }}
              >
                <PersonOutlineRounded />
              </Avatar>
              <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
                <Typography variant="md" type="medium">
                  Profile details
                </Typography>
                <Typography variant="sm" color="text.secondary">
                  Keep your profile details updated
                </Typography>
              </Box>
              <ArrowForwardIosRounded
                sx={{ color: theme.palette.gray.dark1, p: 0.5 }}
              />
            </Box>
            {props?.schoolInfo?.school_name?.length > 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 1.5,

                  // mb: 2,
                  border: theme.border.main,
                  borderRadius: 4,
                }}
                boxShadow={theme.shadows[1]}
              >
                <Avatar
                  sx={{
                    p: 0.4,
                    backgroundColor: theme.palette.gray.light3,
                  }}
                  src={props?.schoolInfo.school_logo}
                >
                  <PersonOutlineRounded />
                </Avatar>
                <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
                  <Typography variant="md" type="medium">
                    {props?.schoolInfo?.school_name}
                  </Typography>
                </Box>
                <Box></Box>
              </Box>
            ) : (
              ""
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1.5,
                border: theme.border.main,
                borderRadius: 4,
              }}
              boxShadow={theme.shadows[1]}
              onClick={() => {
                navigate("/profile/changepassword");
                setShowProfileDrawer(false);
              }}
            >
              <Avatar
                sx={{
                  color: theme.palette.blueDark.dark2,
                  backgroundColor: theme.palette.gray.light3,
                }}
              >
                <SyncLockRounded />
              </Avatar>
              <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
                <Typography variant="md" type="medium">
                  Change password
                </Typography>
                <Typography variant="sm" color="text.secondary">
                  Update your password for safety
                </Typography>
              </Box>
              <ArrowForwardIosRounded
                sx={{ color: theme.palette.gray.dark2, p: 0.5 }}
              />
            </Box>
            {!isB2b &&
            (props?.user?.userData?.user_level === 11 ||
              (props?.user?.userData?.user_level === 13 && studentReferral)) ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 1.5,
                  border: theme.border.main,
                  borderRadius: 4,
                }}
                onClick={() => {
                  navigate(
                    props?.user?.userData?.user_level === 13
                      ? "/student-refer"
                      : "/teacher-refer"
                  );
                }}
                boxShadow={theme.shadows[1]}
              >
                <Avatar
                  sx={{
                    color: theme.palette.blueDark.dark2,
                    backgroundColor: theme.palette.gray.light3,
                  }}
                >
                  <GroupAddOutlinedIcon />
                </Avatar>
                <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
                  <Typography variant="md" type="medium">
                    {props?.user?.userData?.user_level === 13
                      ? "Orchids Ambassador Program"
                      : "Teacher's Referral Programme"}
                  </Typography>
                </Box>
                <ArrowForwardIosRounded
                  sx={{ color: theme.palette.gray.dark1, p: 0.5 }}
                />
              </Box>
            ) : null}
            {!isB2b && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 1.5,

                  // mb: 2,
                  border: theme.border.main,
                  borderRadius: 4,
                }}
                boxShadow={theme.shadows[1]}
                onClick={() => {
                  navigate("/grievance");
                  setShowProfileDrawer(false);
                }}
              >
                <Avatar
                  sx={{
                    color: theme.palette.blueDark.dark2,
                    backgroundColor: theme.palette.gray.light3,
                  }}
                >
                  <HelpOutline />
                </Avatar>
                <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
                  <Typography variant="md" type="medium">
                    Help & Support
                  </Typography>
                  <Typography variant="sm" color="text.secondary">
                    Want to help us improve the App
                  </Typography>
                </Box>
                <ArrowForwardIosRounded
                  sx={{ color: theme.palette.gray.dark2, p: 0.5 }}
                />
              </Box>
            )}

            <ButtonBase
              id="ui-logout-button"
              sx={{ width: "100%", p: 1 }}
              onClick={() => handleLogout()}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <LogoutTwoTone
                  sx={{ mr: 0.2, p: 0.5, color: theme.palette.error.main }}
                />
                <Box sx={{}}>
                  <Typography variant="sm" color={theme.palette.error.main}>
                    Logout
                  </Typography>
                </Box>
              </Box>
            </ButtonBase>
            <Box sx={{ width: "100%", textAlign: "center", py: 2 }}>
              <Typography variant="xs">
                Copyright © 2023, K12 Techno Services Pvt. Ltd.
              </Typography>
              <br />
              <Typography variant="xs" textAlign={"center"}>
                version: {global.appVersion} <br />
              </Typography>
            </Box>

            <Box sx={{ height: 80 }}></Box>
          </Box>
        </CardContent>
      </Card>
      <SelectProfileDrawer
        showProfilesDialog={showProfilesDialog}
        setShowProfilesDialog={setShowProfilesDialog}
        profilesDataDialog={props?.userList}
      />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state?.user,
  schoolInfo: state?.user?.schoolDetails,
  erpUserId: state?.user?.userData?.role_details?.erp_user_id,
  userList: state?.user?.userList,
});

const mapActionsToProps = {
  logoutUser,
};

export default connect(mapStateToProps, mapActionsToProps)(ProfileDrawer);
