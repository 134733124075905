import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Box, ThemeProvider } from "@mui/material";
import jwtDecode from "jwt-decode";
import theme from "./util/theme";

//Import Mixpanel SDK
// import mixpanel from "mixpanel-browser";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import {
  SET_ACADEMIC_YEAR,
  SET_ACAD_YEAR_LIST,
  SET_AUTHENTICATED,
  SET_BRANCH,
  SET_BRANCH_LIST,
  SET_CURRENT_ACADEMIC_YEAR,
  SET_MODULE_ID,
  SET_USER,
  SET_USER_NAVIGATION_DATA,
  SET_USER_APPS_DATA,
  SET_USER_LIST,
  SET_USER_CONTACT,
  SET_SCHOOL_DETAILS,
  SET_SYSTEM_CONFIG,
} from "./redux/types";
import {
  logoutUser,
  setDefaultAcadYearAndBranch,
} from "./redux/actions/userActions";

import PublicRoutes from "./util/PublicRoutes";
import PrivateRoutes from "./util/PrivateRoutes";
import AlertNotificationProvider from "./context-api/alert-context/alert-state";
import AttachmentPreviewer from "./components/attachment-previewer";

import LoadingSvg from "./assets/anims/loading.svg";

// Pages
import QRContent from "./screens/QRContent";
import Home from "./screens/Home";
import NotAuth from "./screens/NotAuth";
import NotFound from "./screens/NotFound";
import Dashboard from "./screens/Dashboard";
import Login from "./screens/Login";
import Diary from "./screens/Diary";
import Profile from "./screens/Profile/Profile";
// import ChangePassword from "screens/Profile/ChangePassword";
import SelectProfilePage from "screens/Login/SelectProfilePage";
import { isB2b, isDevMode } from "apiConfig";
import RelationManager from "screens/RelationManager";
// import ThemeDemo from "screens/ThemeDemo";
import LoginOptionPage from "screens/Login/LoginOptionPage";
import mixpanel from "mixpanel-browser";
import LoginApp from "screens/Login/LoginApp";

const Vacancies = lazy(() => import("./screens/Vacancies"));
const VacancySuccess = lazy(() => import("./screens/Vacancies/SuccessPage"));

const StudentDashboard = lazy(() => import("./screens/StudentDashboard"));
const AssessmentList = lazy(() =>
  import("./screens/Assessement/AssessmentList")
);

const StudentReport = lazy(() => import("./screens/Report"));

const Assessment = lazy(() => import("./screens/Assessement/Assessement"));
const Announcements = lazy(() =>
  import("./screens/Announcements/Announcements")
);
const FeePayment = lazy(() => import("./screens/FeePayment"));
const Scorecard = lazy(() => import("./screens/Assessement/Scorecard"));
const AssessementResponses = lazy(() =>
  import("./screens/Assessement/AssessmentResponses")
);
const Ebook = lazy(() => import("./screens/ebook"));
const Ibook = lazy(() => import("./screens/ibook"));
const OnlineBooks = lazy(() => import("./screens/OnlineBooks"));
const LessonPlan = lazy(() => import("./screens/LessonPlan"));
const SchoolWall = lazy(() => import("./screens/SchoolWall"));
const LiveBusStatus = lazy(() => import("./screens/BusTrack/LiveBusStatus"));
const BusTripsDetail = lazy(() => import("./screens/BusTrack"));
const BusDetails = lazy(() => import("screens/BusTrack/BusDetails"));
const MarkstudentAttendance = lazy(() =>
  import("screens/BusTrack/BusDetails/MarkstudentAttendance")
);
const Homework = lazy(() => import("./screens/Homework"));
const PaymentSuccess = lazy(() =>
  import("./screens/FeePayment/paymentSuccess")
);
const PaymentFailure = lazy(() =>
  import("./screens/FeePayment/paymentFailure")
);
const HomeworkSubmit = lazy(() => import("./screens/Homework/HomeworkSubmit"));
const SubmittedHW = lazy(() => import("./screens/Homework/SubmittedHW"));
const EvaluatedHW = lazy(() => import("./screens/Homework/EvaluatedHW"));
const Calendar = lazy(() => import("./screens/Calendar"));
const Attendance = lazy(() => import("./screens/Attedance"));
const TimeTable = lazy(() => import("./screens/TimeTable"));
const ViewTimeTable = lazy(() => import("./screens/TimeTable/ViewTimeTable"));
const AttendanceMonthly = lazy(() =>
  import("./screens/Attedance/AttendanceMonthly")
);
const EditProfile = lazy(() => import("./screens/Profile/EditProfile"));
const ChangePassword = lazy(() => import("./screens/Profile/ChangePassword"));
const LessonPlanTeacher = lazy(() => import("./screens/Teacher/LessonPlan"));
const LPChapterTeacher = lazy(() =>
  import("./screens/Teacher/LessonPlan/LPChapterTeacher")
);
const LPKeyConceptTeacher = lazy(() =>
  import("./screens/Teacher/LessonPlan/LPKeyConceptTeacher")
);
const AttendanceTeacher = lazy(() => import("./screens/Teacher/Attendance"));
const LPResourcesTeacher = lazy(() =>
  import("./screens/Teacher/LessonPlan/LPResourcesTeacher")
);
const LPPeriodViewTeacher = lazy(() =>
  import("./screens/Teacher/LessonPlan/PeriodView/LPPeriodViewTeacher")
);
const LPPeriodResourcesTeacher = lazy(() =>
  import("./screens/Teacher/LessonPlan/PeriodView/LPPeriodResourcesTeacher")
);
const CalendarTeacher = lazy(() => import("./screens/Teacher/Calendar"));
const Grievance = lazy(() => import("./screens/Grievance"));
const ChatPage = lazy(() => import("./screens/Grievance/ChatPage"));
const TeacherDiary = lazy(() => import("./screens/Teacher/Diary/"));

const CreateGeneralDiary = lazy(() =>
  import("./screens/Teacher/Diary/NewDiary/CreateGeneralDiary")
);
const CreateDailyNewDiary = lazy(() =>
  import("./screens/Teacher/Diary/NewDiary/CreateDailyNewDiary")
);
const CreateDailyDiary = lazy(() =>
  import("./screens/Teacher/Diary/OldDiary/CreateDailyDiary")
);
const DailyDiaryDetail = lazy(() =>
  import("./screens/Teacher/Diary/NewDiary/DailyDiaryDetails")
);
const AnnouncementsTeacher = lazy(() =>
  import("./screens/Teacher/Announcements")
);
const AnnouncementCreation = lazy(() =>
  import("./screens/Teacher/Announcements/AnnouncementCreation")
);
const HomeworkTeacher = lazy(() => import("./screens/Teacher/Homework"));
const AttendancePrincial = lazy(() => import("./screens/Principal/Attendance"));
const AssessmentStudentReports = lazy(() =>
  import("./screens/Teacher/Assessment/IndividualStudentReport")
);
const CreateTest = lazy(() =>
  import("./screens/Teacher/Assessment/CreateTest")
);
const AssessmentReports = lazy(() =>
  import("./screens/Teacher/Assessment/AssessmentReports")
);
const AddHomework = lazy(() =>
  import("./screens/Teacher/Homework/AddHomework")
);
const RoleProtectRoute = lazy(() => import("./util/RoleProtectRoute"));
const TimeTablePrincipal = lazy(() => import("./screens/Principal/TimeTable"));
const ViewTimeTablePrincipal = lazy(() =>
  import("./screens/Principal/TimeTable/v1/ViewTimeTable")
);
const ViewHomework = lazy(() =>
  import("./screens/Teacher/Homework/ViewHomework")
);
const SubmittedHw = lazy(() =>
  import("./screens/Teacher/Homework/SubmittedHw")
);
const EvaluateHw = lazy(() => import("./screens/Teacher/Homework/EvaluateHw"));
const EvaluatedHwTeacher = lazy(() =>
  import("./screens/Teacher/Homework/EvaluatedHwTeacher")
);
const CreateOnlineClass = lazy(() =>
  import("./screens/Teacher/OnlineClass/CreateOnlineClass")
);
const DailyDiaryDetails = lazy(() =>
  import("./screens/Diary/DiaryNew/DailyDiaryDetails")
);
const Notification = lazy(() => import("./screens/Notification"));
const OnlineClass = lazy(() => import("./screens/OnlineClass"));
const ViewOnlineClass = lazy(() =>
  import("./screens/Teacher/OnlineClass/ViewOnlineClass")
);
const Resources = lazy(() => import("./screens/Teacher/OnlineClass/Resources"));
const MySchoolGate = lazy(() => import("./screens/MySchoolGate"));
const NewEntry = lazy(() => import("./screens/MySchoolGate/NewEntry"));
const WaitTime = lazy(() => import("./screens/MySchoolGate/WaitTime"));
const VisitDetail = lazy(() => import("./screens/MySchoolGate/VisitDetail"));
const DiaryActivityPA = lazy(() =>
  import("./screens/Diary/DiaryNew/DiaryActivityPA")
);
const DiaryActivityPS = lazy(() =>
  import("./screens/Diary/DiaryNew/DiaryActivityPS")
);
const TeacherCircular = lazy(() => import("./screens/Teacher/Circular"));
const CreateCircular = lazy(() =>
  import("./screens/Teacher/Circular/CreateCircular")
);
const Circular = lazy(() => import("./screens/Circular"));

const ObservationEvaluation = lazy(() =>
  import("./screens/Observation/ObservationEvaluation")
);

const EvaluationList = lazy(() =>
  import("./screens/Observation/EvaluationList")
);

const ObservationEvaluationV2 = lazy(() =>
  import("./screens/ObservationV2/Teacher/ObservationEvaluation")
);
const EvaluationListV2 = lazy(() =>
  import("./screens/ObservationV2/Teacher/EvaluationList")
);
const EvaluationFormPRM = lazy(() =>
  import("./screens/ObservationV2/PRM/EvaluationFormPRM")
);
const EvaluationFormPRO = lazy(() =>
  import("./screens/ObservationV2/PRO/EvaluationFormPRO")
);
const EvaluationFormVideo = lazy(() =>
  import("./screens/ObservationV2/Video/EvaluationFormVideo")
);
const ObservationEvaluationTeacher = lazy(() =>
  import("./screens/Teacher/ObservationEvaluation")
);
const ObservationMenu = lazy(() =>
  import("./screens/ObservationV2/ObservationMenu")
);
const ObservationListPRO = lazy(() =>
  import("./screens/ObservationV2/PRO/ObservationList")
);
const ObservationListPRM = lazy(() =>
  import("./screens/ObservationV2/PRM/ObservationList")
);
const ObservationListVideo = lazy(() =>
  import("./screens/ObservationV2/Video/ObservationList")
);
const ObservationListVideoObservation = lazy(() =>
  import("./screens/ObservationV2/VideoObservation/ObservationList")
);
const EvaluationFormVideoObservation = lazy(() =>
  import(
    "./screens/ObservationV2/VideoObservation/EvaluationFormVideoObservation"
  )
);
const EvaluationFormOpsManager = lazy(() =>
  import("./screens/ObservationV2/OpsManager/EvaluationFormOpsManager")
);
const EvaluationFormCIC = lazy(() =>
  import("./screens/ObservationV2/CIC/EvaluationFormCIC")
);
const EvaluationFormTIC = lazy(() =>
  import("./screens/ObservationV2/TIC/EvaluationFormTIC")
);
const EvaluationListNonAcad = lazy(() =>
  import("./screens/ObservationV2/NonAcademicObservationList/EvaluationList")
);
const NonAcademicObservationList = lazy(() =>
  import("./screens/ObservationV2/NonAcademicObservationList/index")
);
const TeacherReport = lazy(() =>
  import("./screens/ObservationV2/TeacherReport")
);
const CallAudit = lazy(() => import("./screens/ObservationV2/PRM/CallAudit"));
const StudentMyReferrals = lazy(() =>
  import("./screens/Referral/studentMyReferrals")
);
const StudentReferralForm = lazy(() =>
  import("./screens/Referral/studentReferralForm")
);
const TeacherMyReferrals = lazy(() =>
  import("./screens/Referral/teacherMyReferrals")
);
const TeacherReferralForm = lazy(() =>
  import("./screens/Referral/teacherReferralForm")
);
const TeacherTimeTable = lazy(() => import("./screens/Teacher/TimeTable/"));
const ActivityManagement = lazy(() =>
  import("./screens/Principal/ActivityManagement")
);
const HwDashboard = lazy(() =>
  import("./screens/Teacher/Homework/HwDashboard")
);
const StudentHwDashboard = lazy(() => import("./screens/Homework/HwDashboard"));
const ViewUser = lazy(() => import("./screens/UserManagement/ViewUser"));

const branch = localStorage.getItem("branch");

const userData = localStorage.user_details;
if (userData) {
  store.dispatch({
    type: SET_USER,
    payload: JSON.parse(userData),
  });
}

if (userData) {
  store.dispatch({ type: SET_AUTHENTICATED });
  if (branch !== "undefined") {
    if (!JSON.parse(branch)) {
      setDefaultAcadYearAndBranch();
    } else {
      store.dispatch({
        type: SET_BRANCH,
        payload: JSON.parse(branch),
      });
      store.dispatch({
        type: SET_ACAD_YEAR_LIST,
        payload: JSON.parse(localStorage.getItem("acad_year_list")),
      });

      store.dispatch({
        type: SET_CURRENT_ACADEMIC_YEAR,
        payload: JSON.parse(localStorage.getItem("current_acad_year")),
      });
      store.dispatch({
        type: SET_BRANCH_LIST,
        payload: JSON.parse(localStorage.getItem("branch_list")),
      });
    }
  }
}

const userAcademicYear = localStorage.academicYear;
if (userAcademicYear) {
  store.dispatch({
    type: SET_ACADEMIC_YEAR,
    payload: JSON.parse(userAcademicYear),
  });
}

const userNavigationData = localStorage.user_navigation_data;
if (userNavigationData) {
  store.dispatch({
    type: SET_USER_NAVIGATION_DATA,
    payload: JSON.parse(userNavigationData),
  });

  store.dispatch({
    type: SET_MODULE_ID,
    payload: JSON.parse(userNavigationData)
      ?.find((o) => o.parent_modules === "Ebook")
      ?.child_module.find((o) => o.child_name === "Ebook View")?.child_id,
  });
}

const userAppsData = localStorage.user_apps_data;
if (userAppsData) {
  store.dispatch({
    type: SET_USER_APPS_DATA,
    payload: JSON.parse(userAppsData),
  });
}

const userList = localStorage.user_list;
if (userList) {
  store.dispatch({
    type: SET_USER_LIST,
    payload: JSON.parse(userList),
  });
}

const userContact = localStorage.user_contact;
if (userContact) {
  store.dispatch({
    type: SET_USER_CONTACT,
    payload: JSON.parse(userContact),
  });
}

const schoolDetails = localStorage.school_details;
if (schoolDetails) {
  store.dispatch({
    type: SET_SCHOOL_DETAILS,
    payload: JSON.parse(schoolDetails),
  });
}

const sysConfig = localStorage.system_config;
if (sysConfig) {
  store.dispatch({
    type: SET_SYSTEM_CONFIG,
    payload: JSON.parse(sysConfig),
  });
}

function App() {
  if (window.location.pathname === "/") {
    console.log("At root");
  } else {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
    } else {
      if (window.location.pathname === "/m/grievance") {
        window.location.href =
          "https://orchids.finance.letseduvate.com/#/ticket";
      } else {
        if (!isDevMode) {
          window.location.href = "/";
        }
      }
    }
  }

  const platform = localStorage.getItem("platform");

  useEffect(() => {
    if (window.location.pathname != "/m") {
      try {
        window.flutter_inappwebview.callHandler("appLoadedHandler", {});
      } catch (err) {
        console.log(err);
      }
    }

    // init Mixpanel
    if (!isB2b) {
      mixpanel.init("1a74c2c62a329aabf4eabc67877909b7", {
        debug: true,
        track_pageview: true,
        persistence: "localStorage",
      });
    }
  }, []);

  return (
    <Suspense
      fallback={
        <Box
          style={{
            height: "100vh",
            width: "100vw",
          }}
        >
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img src={LoadingSvg} alt="loading..." />
          </Box>
        </Box>
      }
    >
      <ThemeProvider theme={theme}>
        <AlertNotificationProvider>
          <AttachmentPreviewer>
            <Provider store={store}>
              <Router basename="/m">
                <Routes>
                  {/* Public Routes */}
                  <Route element={<PublicRoutes />}>
                    <Route exact path="" element={<Login />} />
                    <Route
                      exact
                      path="login-option"
                      element={<LoginOptionPage />}
                    />
                    <Route exact path="login" element={<Login />} />
                    <Route exact path="login-app" element={<LoginApp />} />
                    <Route
                      exact
                      path="select-profile"
                      element={<SelectProfilePage />}
                    />
                    {/* <Route element={<RoleProtectRoute roles={[8, 57, 26]} />}> */}

                    {/* </Route> */}
                  </Route>

                  {/* Private Routes */}
                  <Route element={<PrivateRoutes />}>
                    {/* <Route exact path="theme" element={<ThemeDemo />} /> */}

                    <Route
                      element={
                        <RoleProtectRoute
                          roles={[2, 8, 9, 10, 26, 27, 34, 47, 57]}
                        />
                      }
                    >
                      <Route
                        exact
                        path="observation-evaluation-v1"
                        element={<ObservationEvaluation />}
                      />
                      <Route
                        exact
                        path="observation-evaluation-list-v1"
                        element={<EvaluationList />}
                      />
                      <Route
                        exact
                        path="observation-evaluation"
                        element={<ObservationEvaluationV2 />}
                      />
                      <Route
                        exact
                        path="observation-evaluation-list"
                        element={<EvaluationListV2 />}
                      />
                      <Route
                        exact
                        path="prm-call-audit"
                        element={<CallAudit />}
                      />
                      <Route
                        exact
                        path="observation-evaluation-prm"
                        element={<EvaluationFormPRM />}
                      />
                      <Route
                        exact
                        path="observation-list-prm"
                        element={<ObservationListPRM />}
                      />
                      <Route
                        exact
                        path="observation-evaluation-pro"
                        element={<EvaluationFormPRO />}
                      />
                      <Route
                        exact
                        path="observation-list-video"
                        element={<ObservationListVideo />}
                      />
                      <Route
                        exact
                        path="observation-evaluation-video"
                        element={<EvaluationFormVideo />}
                      />

                      <Route
                        exact
                        path="observation-list-pro"
                        element={<ObservationListPRO />}
                      />
                      <Route
                        exact
                        path="observation-evaluation-menu"
                        element={<ObservationMenu />}
                      />
                      <Route
                        exact
                        path="observation/teacher-report"
                        element={<TeacherReport />}
                      />

                      {/* Ops Manager */}

                      <Route
                        exact
                        path="observation-evaluation-ops-manager"
                        element={<EvaluationFormOpsManager />}
                      />
                      <Route
                        exact
                        path="observation-evaluation-tic"
                        element={<EvaluationFormTIC />}
                      />
                      <Route
                        exact
                        path="observation-evaluation-cic"
                        element={<EvaluationFormCIC />}
                      />
                      <Route
                        exact
                        path="observation-list-non-acad"
                        element={<EvaluationListNonAcad />}
                      />
                    </Route>

                    {/* All Observations Users + Teacher (11) */}
                    <Route
                      element={
                        <RoleProtectRoute
                          roles={[11, 2, 8, 9, 10, 26, 27, 34, 47]}
                        />
                      }
                    >
                      <Route
                        exact
                        path="observation/teacher-view"
                        element={<ObservationEvaluationTeacher />}
                      />
                    </Route>

                    {/* Video Editor CCA*/}
                    <Route element={<RoleProtectRoute roles={[64]} />}>
                      <Route
                        exact
                        path="observation-list-videolink"
                        element={<ObservationListVideoObservation />}
                      />
                      <Route
                        exact
                        path="observation-evaluation-videolink-add"
                        element={<EvaluationFormVideoObservation />}
                      />
                    </Route>

                    {/* <<<<____ BOTTOM NAV LAYOUT PAGES ____>>>> */}
                    <Route path="dashboard" element={<Dashboard />}>
                      <Route path="home" element={<Home />} />
                      <Route element={<RoleProtectRoute roles={[13]} />}>
                        <Route path="diary" element={<Diary />} />
                      </Route>

                      <Route element={<RoleProtectRoute roles={[11]} />}>
                        <Route
                          path="timetable-teacher"
                          element={<TeacherTimeTable />}
                        />
                      </Route>

                      <Route path="profile" element={<Profile />} />
                    </Route>

                    {/* <<<<____ COMMON PAGES ____>>>> */}
                    {/* Profile */}
                    <Route
                      exact
                      path="profile/edit"
                      element={<EditProfile />}
                    />

                    {/* Change Password */}
                    <Route
                      exact
                      path="profile/changepassword"
                      element={<ChangePassword />}
                    />

                    {/* Notification */}
                    <Route
                      exact
                      path="notification"
                      element={<Notification />}
                    />

                    <Route
                      exact
                      path="myschoolgate"
                      element={<MySchoolGate />}
                    />

                    <Route
                      exact
                      path="myschoolgate/new-entry"
                      element={<NewEntry />}
                    />
                    <Route
                      exact
                      path="myschoolgate/new-entry/wait-time"
                      element={<WaitTime />}
                    />
                    <Route
                      exact
                      path="myschoolgate/wait-time/:visitId"
                      element={<WaitTime />}
                    />

                    {/* <<<<____ STUDENT PAGES ____>>>> */}
                    <Route element={<RoleProtectRoute roles={[13]} />}>
                      {/* Diary */}
                      <Route exact path="diary" element={<Diary />} />

                      {/* <Route
                        exact
                        path="student-dashboard"
                        element={<StudentDashboard />}
                      /> */}

                      <Route
                        exact
                        path="diary/detail"
                        element={<DailyDiaryDetails />}
                      />

                      <Route
                        exact
                        path="diary/detail/activity/pa"
                        element={<DiaryActivityPA />}
                      />

                      <Route
                        exact
                        path="diary/detail/activity/ps"
                        element={<DiaryActivityPS />}
                      />

                      {/* Announcements */}
                      <Route
                        exact
                        path="announcements"
                        element={<Announcements />}
                      />
                      {/* Assessment */}
                      <Route
                        exact
                        path="assessment-list"
                        element={<AssessmentList />}
                      />
                      <Route
                        exact
                        path="assessment/:questionPaperId/:assessmentId/attempt"
                        element={<Assessment />}
                      />
                      <Route
                        exact
                        path="assessment/scorecard"
                        element={<Scorecard />}
                      />
                      <Route
                        exact
                        path="assessment/assessment-responses"
                        element={<AssessementResponses />}
                      />

                      {/* Report  */}

                      <Route
                        exact
                        path="student-report"
                        element={<StudentReport />}
                      />

                      {/* Fee Payment */}
                      <Route
                        exact
                        path="fee-payment"
                        element={<FeePayment />}
                      />
                      <Route
                        exact
                        path="payment-success"
                        element={<PaymentSuccess />}
                      />
                      <Route
                        exact
                        path="payment-failure"
                        element={<PaymentFailure />}
                      />

                      {/* Lesson Plan */}
                      <Route
                        exact
                        path="lesson-plan"
                        element={<LessonPlanTeacher />}
                      />
                      {/* <Route
                        exact
                        path="lesson-plan-teacher"
                        element={<LessonPlanTeacher />}
                      /> */}
                      <Route
                        exact
                        path="lesson-plan/chapter-list"
                        element={<LPChapterTeacher />}
                      />
                      <Route
                        exact
                        path="lesson-plan/key-concept-list"
                        element={<LPKeyConceptTeacher />}
                      />
                      <Route
                        exact
                        path="lesson-plan/resources"
                        element={<LPResourcesTeacher />}
                      />
                      <Route
                        exact
                        path="lesson-plan/period-view"
                        element={<LPPeriodViewTeacher />}
                      />
                      <Route
                        exact
                        path="lesson-plan/period-view/resources"
                        element={<LPPeriodResourcesTeacher />}
                      />
                      {/* Home Work */}
                      <Route exact path="homework" element={<Homework />} />
                      <Route
                        exact
                        path="homework-submit/1/:hwId"
                        element={<HomeworkSubmit />}
                      />
                      <Route
                        exact
                        path="homework-submit/2/:hwId"
                        element={<SubmittedHW />}
                      />
                      <Route
                        exact
                        path="homework-submit/3/:hwId"
                        element={<EvaluatedHW />}
                      />
                      <Route
                        exact
                        path="student-hw-dashboard"
                        element={<StudentHwDashboard />}
                      />
                      {/* Calendar */}
                      <Route exact path="calendar" element={<Calendar />} />
                      {/* Attendance */}
                      <Route exact path="attendance" element={<Attendance />} />
                      <Route
                        exact
                        path="attendance-monthly/:year/:month"
                        element={<AttendanceMonthly />}
                      />
                      {/* Time-Table */}
                      <Route exact path="timetable" element={<TimeTable />} />
                      {/* View TimeTable */}
                      <Route
                        exact
                        path="viewtimetable/:ttId"
                        element={<ViewTimeTable />}
                      />
                      {/* Grievance */}
                      <Route exact path="grievance" element={<Grievance />} />
                      <Route
                        exact
                        path="grievance/:id"
                        element={<ChatPage />}
                      />

                      {/* SchoolWall */}
                      <Route
                        exact
                        path="school-wall/"
                        element={<SchoolWall />}
                      />

                      {/* Online-class */}
                      <Route
                        exact
                        path="online-class"
                        element={<OnlineClass />}
                      />
                      <Route
                        exact
                        path="myschoolgate"
                        element={<MySchoolGate />}
                      />
                      <Route
                        exact
                        path="myschoolgate/new-entry"
                        element={<NewEntry />}
                      />
                      <Route
                        exact
                        path="myschoolgate/new-entry/wait-time"
                        element={<WaitTime />}
                      />
                      <Route
                        exact
                        path="myschoolgate/visit/:visitId"
                        element={<VisitDetail />}
                      />

                      {/* Bus-Trips-Detail */}
                      <Route
                        exact
                        path="trips-detail"
                        element={<BusTripsDetail />}
                      />

                      {/* Bus-Tracking */}
                      <Route
                        exact
                        path="bus-track/:id"
                        element={<LiveBusStatus />}
                      />

                      {/* Circular */}
                      <Route exact path="circular" element={<Circular />} />

                      {/* My Refferals */}
                      <Route
                        exact
                        path="student-refer"
                        element={<StudentMyReferrals />}
                      />
                      <Route
                        exact
                        path="referral-form"
                        element={<StudentReferralForm />}
                      />
                    </Route>

                    {/* <<<<____ TEACHER PAGES ____>>>> */}
                    <Route element={<RoleProtectRoute roles={[11]} />}>
                      {/* Time Table */}
                      <Route
                        exact
                        path="timetable-teacher"
                        element={<TeacherTimeTable />}
                      />

                      {/* Attendance */}
                      <Route
                        exact
                        path="attendance-teacher"
                        element={<AttendanceTeacher />}
                      />

                      {/*....... Assessment...... */}
                      <Route
                        exact
                        path="create-test"
                        element={<CreateTest />}
                      />

                      <Route
                        exact
                        path="online-class/view"
                        element={<ViewOnlineClass />}
                      />
                      <Route
                        exact
                        path="online-class/resources"
                        element={<Resources />}
                      />
                      <Route
                        exact
                        path="online-class/create"
                        element={<CreateOnlineClass />}
                      />
                      {/* My Refferals */}
                      <Route
                        exact
                        path="teacher-refer"
                        element={<TeacherMyReferrals />}
                      />
                      <Route
                        exact
                        path="teacher-referral-form"
                        element={<TeacherReferralForm />}
                      />
                    </Route>

                    {/* <<<<____ PRINCIPAL PAGES(8) ++ COORDINATOR(10) ____>>>> */}

                    <Route element={<RoleProtectRoute roles={[8, 10]} />}>
                      {/* Attendance */}
                      <Route
                        exact
                        path="attendance-principal"
                        element={<AttendancePrincial />}
                      />

                      {/* Time Table */}
                      <Route
                        exact
                        path="timetable-principal"
                        element={<TimeTablePrincipal />}
                      />
                      {/* View TimeTable */}
                      <Route
                        exact
                        path="viewtimetable-principal/:ttId"
                        element={<ViewTimeTablePrincipal />}
                      />
                    </Route>

                    {/* <<<<____ TEACHER(11) ++ PRINCIPAL PAGES(8) ++ COORDINATOR(10)) ____>>>> */}
                    <Route element={<RoleProtectRoute roles={[8, 11, 10]} />}>
                      {/* Announcements */}
                      <Route
                        exact
                        path="announcements-teacher"
                        element={<AnnouncementsTeacher />}
                      />
                      <Route
                        exact
                        path="announcements-create"
                        element={<AnnouncementCreation />}
                      />

                      {/* Calendar */}
                      <Route
                        exact
                        path="calendar-teacher"
                        element={<CalendarTeacher />}
                      />

                      {/* Assessment-Reports */}
                      <Route
                        exact
                        path="assessment-reports"
                        element={<AssessmentReports />}
                      />
                      <Route
                        exact
                        path="assessment-student-report"
                        element={<AssessmentStudentReports />}
                      />
                      {/* Circular */}
                      <Route
                        exact
                        path="teacher-circular"
                        element={<TeacherCircular />}
                      />
                      <Route
                        exact
                        path="teacher-circular/create"
                        element={<CreateCircular />}
                      />

                      {/* User Management */}

                      <Route
                        exact
                        path="user-management/view-user"
                        element={<ViewUser />}
                      />

                      {/* Activity Management Dashboard */}
                      <Route
                        exact
                        path="activity-management"
                        element={<ActivityManagement />}
                      />
                    </Route>

                    {/* <<<<____ TEACHER(11) ++ PRINCIPAL PAGES(8) ++ COORDINATOR(10)) + CENTRAL TEAM (1,2,26,27) ____>>>> */}
                    <Route
                      element={
                        <RoleProtectRoute roles={[8, 11, 10, 1, 2, 26, 27]} />
                      }
                    >
                      {/* Lesson Plan */}
                      <Route
                        exact
                        path="lesson-plan-teacher"
                        element={<LessonPlanTeacher />}
                      />
                      <Route
                        exact
                        path="lesson-plan-teacher/chapter-list"
                        element={<LPChapterTeacher />}
                      />
                      <Route
                        exact
                        path="lesson-plan-teacher/key-concept-list"
                        element={<LPKeyConceptTeacher />}
                      />
                      <Route
                        exact
                        path="lesson-plan-teacher/resources"
                        element={<LPResourcesTeacher />}
                      />
                      <Route
                        exact
                        path="lesson-plan-teacher/period-view"
                        element={<LPPeriodViewTeacher />}
                      />
                      <Route
                        exact
                        path="lesson-plan-teacher/period-view/resources"
                        element={<LPPeriodResourcesTeacher />}
                      />

                      {/* Diary */}
                      <Route
                        exact
                        path="diary-teacher"
                        element={<TeacherDiary />}
                      />

                      {/* Create-General-diary */}
                      <Route
                        exact
                        path="create-general-diary"
                        element={<CreateGeneralDiary />}
                      />

                      {/* Create-Daily-diary */}
                      <Route
                        exact
                        path="create-daily-diary"
                        element={<CreateDailyDiary />}
                      />

                      {/* Create-Daily-NewDiary */}
                      <Route
                        exact
                        path="create-daily-NewDiary"
                        element={<CreateDailyNewDiary />}
                      />

                      {/* Daily-Diary-Details */}
                      <Route
                        exact
                        path="diary-teacher/detail"
                        element={<DailyDiaryDetail />}
                      />

                      {/* Homework */}
                      <Route
                        exact
                        path="homework-teacher"
                        element={<HomeworkTeacher />}
                      />
                      <Route
                        exact
                        path="homework-teacher/add/:date/:session_year/:branch/:grade/:subject/:id"
                        element={<AddHomework />}
                      />
                      <Route
                        exact
                        path="homework-teacher/view/:homeworkId"
                        element={<ViewHomework />}
                      />
                      <Route
                        exact
                        path="homework-teacher/submitted/:homeworkId"
                        element={<SubmittedHw />}
                      />
                      <Route
                        exact
                        path="homework-teacher/evaluate/:studentHwId"
                        element={<EvaluateHw />}
                      />
                      <Route
                        exact
                        path="homework-teacher/evaluated/:homeworkId"
                        element={<EvaluatedHwTeacher />}
                      />
                    </Route>

                    {/* <<<<____  STUDENT(13) ++ TEACHER(11) ++ PRINCIPAL PAGES(8)  ++ COORDINATE(10) ____>>>> */}
                    <Route
                      element={<RoleProtectRoute roles={[8, 11, 13, 10]} />}
                    >
                      {/* I-book */}
                      <Route exact path="ibook" element={<Ibook />} />

                      {/* E-book */}
                      <Route exact path="ebook" element={<Ebook />} />

                      {/* Online Books */}

                      <Route
                        exact
                        path="online-books"
                        element={<OnlineBooks />}
                      />
                    </Route>
                    {/* QR Content */}
                    {/* <Route exact path="qr-content" element={<QRContent />} /> */}
                  </Route>

                  {/* <<<<____ CENTRAL(2) ++ TEACHER(11) ++ PRINCIPAL PAGES(8) ++ COORDINATOR(10) ____>>>> */}
                  <Route element={<RoleProtectRoute roles={[2, 8, 11, 10]} />}>
                    {/* Homework Dashboard */}
                    <Route
                      exact
                      path="hw-dashboard"
                      element={<HwDashboard />}
                    />
                  </Route>

                  {/* <<<<____  DRIVER PAGES=15 ++ TRANSPORT ENCHARGE PAGE=28  ____>>>> */}
                  <Route element={<RoleProtectRoute roles={[15, 28]} />}>
                    {/* Bus Detail */}
                    <Route exact path="bus-details" element={<BusDetails />} />

                    {/*Student Attendance in Bus */}
                    <Route
                      exact
                      path="bus-details/:id"
                      element={<MarkstudentAttendance />}
                    />
                  </Route>

                  {/* <<<<____ RELATION MANAGER (47) PAGES ____>>>> */}
                  {/* <Route element={<RoleProtectRoute roles={[14, 47]} />}>
                    <Route
                      exact
                      path="relation-managment"
                      element={<RelationManager />}
                    />
                  </Route> */}

                  {/* <<<<____ CAMPUS INCHARGE(9) ++ TRANSPORT INCHARGE(28) ++ OPERATION MANAGER(26) PAGES ____>>>> */}
                  <Route element={<RoleProtectRoute roles={[9, 26, 28]} />}>
                    <Route
                      exact
                      path="observation/non-acad-view"
                      element={<NonAcademicObservationList />}
                    />
                  </Route>

                  {/* Vacancies */}
                  <Route path="vacancies" element={<Vacancies />} />
                  <Route
                    exact
                    path="vacancy-success"
                    element={<VacancySuccess />}
                  />
                  {/* Others */}
                  <Route path="not-auth" element={<NotAuth />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Router>
            </Provider>
          </AttachmentPreviewer>
        </AlertNotificationProvider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
