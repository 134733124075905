import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";

// MUI Stuff
import { Box, Typography, Container, Avatar, Button } from "@mui/material";

// Icons
import { HomeRounded as HomeRoundedIcon } from "@mui/icons-material";

import Logo from "assets/img/logo.png";

//Redux
import { connect } from "react-redux";
import { loginUser } from "redux/actions/userActions";
import { loginWithOtp } from "redux/actions/userActions";
import { loginWithToken } from "redux/actions/userActions";
import { loginWithMobileNo } from "redux/actions/userActions";

//Files
import SimpleSnackbar from "components/Snackbar";

import axiosInstance from "util/axiosInstance";
import { AlertNotificationContext } from "context-api/alert-context/alert-state";
import SelectProfileDialog from "./SelectProfileDialog";
import {
  LOADING_UI,
  SET_SCHOOL_DETAILS,
  SET_USER_CONTACT,
  SET_USER_LIST,
  STOP_LOADING_UI,
} from "redux/types";
import store from "redux/store";
import axios from "axios";
import { API_CENTRAL, isB2b } from "apiConfig";
import { useSearchParamsState } from "hooks/useSearchParamsState";

import LoadingSvg from "../../assets/anims/loading.svg";

function Copyright(props) {
  return (
    <Typography
      variant="caption"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {new Date().getFullYear()}, K12 Techno Services Pvt. Ltd.
    </Typography>
  );
}

function LoginApp(props) {
  let navigate = useNavigate();

  const { setAlert } = React.useContext(AlertNotificationContext);

  const [shoolDetails, setShoolDetails] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("warning");
  const [forgotPassFlag, setForgotPassFlag] = useState(false);
  const [showProfilesDialog, setShowProfilesDialog] = useState(false);
  const [profilesDataDialog, setProfilesDataDialog] = useState([]);
  const [homeTimer, setHomeTimer] = useState(false);

  const [searchParams] = useSearchParams();

  const [tabParam, setTabParam] = useSearchParamsState(
    "tab",
    Number(searchParams.get("tab"))
  );

  const [value, setValue] = React.useState(Number(tabParam) ?? 0); // tab value

  useEffect(() => {
    setTabParam(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    fetchSchoolDetails();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setHomeTimer(true);
    }, 10000);
  }, []);

  const fetchSchoolDetails = () => {
    let splitedUrlAddress = window.location.origin.split(".");
    let subDomain = splitedUrlAddress[0]?.split("//")[1];
    if (subDomain?.includes("localhost")) {
      subDomain = "qa";
    }
    axios
      .get(
        `${API_CENTRAL}/central-admin/school_details/?sub_domain=${subDomain}`,
        {
          headers: {
            "x-api-key": "vikash@12345#1231",
          },
        }
      )
      .then((res) => {
        setShoolDetails(res?.data);
        store.dispatch({
          type: SET_SCHOOL_DETAILS,
          payload: res?.data,
        });
      });
  };

  const mobileVerifyOtpFunction = (userData) => {
    axiosInstance
      .post(`erp_user/v2/mobile-verify-otp/`, userData)
      .then((res) => {
        if (res.data.status_code === 200) {
          setShowProfilesDialog(true);
          navigate("/select-profile");
          store.dispatch({
            type: SET_USER_LIST,
            payload: res.data.data,
          });
          store.dispatch({
            type: SET_USER_CONTACT,
            payload: userData?.contact,
          });
          setAlert("success", res?.data?.message);

          try {
            window.flutter_inappwebview.callHandler("appLoadedHandler", {});
          } catch (err) {
            console.log(err);
          }
        } else {
          setAlert("error", res?.data?.message);
        }
      });
  };

  useEffect(() => {
    var erpId = searchParams.get("erp_id");
    var password = searchParams.get("password");

    let contact = `+91-${searchParams.get("contact")}`;
    let otp = searchParams.get("otp");
    let token = searchParams.get("token");

    if (erpId && password) {
      const userData = {
        username: erpId,
        password: password,
      };
      props.loginUser(userData, navigate, setOpenSnackbar, setSeverity);
    } else if (erpId && otp) {
      const userData = {
        erp_id: erpId,
        otp: otp,
      };
      props.loginWithOtp(userData, navigate, setOpenSnackbar, setSeverity);
    } else if (contact && otp) {
      const userData = {
        contact: contact,
        otp: otp,
      };
      mobileVerifyOtpFunction(userData);
    } else if (token) {
      const userData = {
        token: token,
      };
      props.loginWithToken(userData, navigate, setOpenSnackbar, setSeverity);
    }
  }, []);

  const timerRef = useRef(null);

  useEffect(() => {
    if (isB2b) {
      timerRef.current = setTimeout(() => {
        window?.$zoho?.salesiq?.floatbutton?.visible("show");
      }, 1000);

      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        window?.$zoho?.salesiq?.floatbutton?.visible("hide");
      };
    }
  }, [window.$zoho]);

  return (
    <Container
      type="main"
      component="main"
      sx={{
        p: 0,
        height: "100vh",
      }}
    >
      <Box
        style={{
          height: "100vh",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ height: "100px" }} />
          <Avatar
            sx={{ width: 64, height: 64, mb: 2 }}
            alt="Letseduvate"
            src={Logo}
          />
          <Typography variant="caption">
            <b>Letseduvate</b>
          </Typography>
        </Box>

        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {homeTimer ? (
            <Button
              variant="text"
              onClick={() => (navigate("/"), setHomeTimer(false))}
              width="100%"
            >
              <HomeRoundedIcon />
              <Box pr={1} />
              Home
            </Button>
          ) : (
            <img src={LoadingSvg} alt="loading..." />
          )}
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            textAlign: "center",
            py: 2,
            display: "grid",
          }}
        >
          <Copyright />
        </Box>
      </Box>

      <SelectProfileDialog
        showProfilesDialog={showProfilesDialog}
        setShowProfilesDialog={setShowProfilesDialog}
        profilesDataDialog={profilesDataDialog}
      />
      <SimpleSnackbar
        message={props.UI.errors}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        severity={severity}
      />
    </Container>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  loginUser,
  loginWithOtp,
  loginWithToken,
  loginWithMobileNo,
};

export default connect(mapStateToProps, mapActionsToProps)(LoginApp);
